<template>
    <PageHeader :title="title" />

    <tablecustom 
        :tabs="othertabs"
        :pages="this.objPages"
        :dataParams="this.dataParams"
        :objParams="this.objParams"
        :rows="this.rows"
        :columns="this.columns"
        :createdform="true"
        @create="this.showModalCreate = true"
        @search="searchB"
        @changeFilter="changeFilter"
        @changeTab="changeFilter"
        @addCategory=""
        @getdata="this.getdata()"
        @open="open"
    />

    <viewCard 
        v-if="this.showModalView == true"
        :obj="this.objCard"
        @close="this.showModalView = false"
        @getdata="this.getdata()"
        @edit="edit"
    />

    <createForm 
        v-if="this.showModalCreate == true"
        :obj="this.objCard"
        :perm="this.perm"
        @close="this.showModalCreate = false"
        @getdata="this.getdata()"
    />
</template>

<script>
import tablecustom from '@/components/globaltable/index.vue' 
import PageHeader from "@/components/page-header";
import viewCard from '@/views/monitoring/view/index.vue'
import createForm from '@/views/monitoring/create.vue'
import {Monitoring, Archive} from '@/API'
import { mutatePriorityTicket, mutateMonitoringType } from '@/usabilityScripts/globalMutate'

let apiServe = new Monitoring()
let apiArchive = new Archive()

export default {
    data() {
        return {
            showFilterBox: true,
            othertabs: [
                {
                    title: this.$t('Payment'),
                    tab: "type",
                    color: "#3577f1",
                    obj: "type",
                    value: "payments",
                    checked: true
                },
                {
                    title: this.$t('trouble_client'),
                    tab: "type",
                    color: "#e21515",
                    obj: "type",
                    value: "problems",
                    checked: true
                },
                {
                    title: this.$t('Other'),
                    tab: "type",
                    color: "#e0930d",
                    obj: "type",
                    value: "other",
                    checked: true
                },
            ],
            perm: false,
            showModalView: false,
            showModalCreate: false,
            objCard: {},
            title: this.$t('monitoringLabel'),
            rows: [],
            objPages: {},
            objParams: {
                priority: '', 
                type: '',
                hideMonitoringByWorker: false,
            },
            dataParams: {
                status: true,
                page: "monitoring"
            },
            columns: [
                {
                    name: "ID",
                    text: "monitoringId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("acc"),
                    text: "number",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Cause"),
                    text: "reason",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("date_time"),
                    text: "createDatetime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Manager"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatePriority(item).name,
                    mutateClass: (item) => this.mutatePriority(item).color
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateType(item).name,
                    mutateClass: (item) => this.mutateType(item).color
                },
                {
                    name: this.$t("appointed"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.mutateAppointed(item, obj),
                },
            ]
        }
    },
    components: {
        PageHeader,
        tablecustom,
        viewCard,
        createForm
    },
    methods: {
        mutateAppointed(item, obj) {
            return obj.assignedWorkers[0] ? obj.assignedWorkers[0].workerName : "-"
        },
        changeFilter(name, value) {
            console.log(name, value)
            if(name === 'all') {
                this.objParams.type = ""
                this.objParams.hideMonitoringByWorker = false
                this.objParams.priority = ""
            } else {
                this.objParams[name] = value
            }
            this.getdata()
        },
        open: function(e){
            // відкриття моніторингу
            console.log(e)
            if(e.monitoringId) {
                apiServe.getMonitoring(e.monitoringId).then(result => {
                    if(result.status == 'done') {
                        this.objCard = result.data
                        this.showModalView = true
                    } else {
                        // todo logs
                    }
                })
            } else {

            }
        },
        mutatePriority(e){
            return mutatePriorityTicket(e)
        },
        mutateType(e) {
            return mutateMonitoringType(e)
        },
        getdata() {
            apiArchive.getAllArchiveMonitoring(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.objPages = result.data;
                    this.rows = result.data.items
                } else {
                    // todo logs
                }
            })
        },

    },
    created() {
        this.getdata()
    }
}
</script>

<style>

</style>